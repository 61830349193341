import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { Aside } from "../../../components/widgets/aside";
import { Border } from "../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../components/widgets/button";
import { PortfolioChart } from "../../../components/widgets/portfolio-ref";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.title}</h1>
    <Breadcrumbs text="Articles" to="/articles/" mdxType="Breadcrumbs" />
    <Aside mdxType="Aside">
      <p><strong parentName="p">{`Further Reading`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://en.wikipedia.org/wiki/Volatility_(finance)"
          }}>{`Volatility`}</a></li>
      </ul>
      <p><strong parentName="p">{` See Also`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/articles/leveraged-etf-portfolios/"
          }}>{`Leveraged ETF Portfolios`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/articles/volatility-targeting/"
          }}>{`Volatility Targeting`}</a></li>
      </ul>
      <p><strong parentName="p">{`Related Portfolios`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/portfolios/tt-dos-equis/"
          }}>{`TT's Dos Equis`}</a></li>
      </ul>
    </Aside>
    <p>{`In a `}<a parentName="p" {...{
        "href": "/articles/leveraged-etf-portfolios/"
      }}>{`previous article`}</a>{`, we discussed the mechanics of leveraged ETFs and how to use them for portfolio construction. The resulting portfolios applied leverage rather sparingly and in conservative ways.`}</p>
    <p>{`In this article, we look at ways to take things a step further. We aim to apply more aggressive leverage to achieve significantly higher returns and yet manage portfolio risk.`}</p>
    <h2>{`Leveraged Assets`}</h2>
    <p>{`To apply leverage, we will be using leveraged ETFs. These ETFs have some remarkable characteristics, which we need to understand before diving deeper into strategy development,`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d251b5b10bddf4f96d81dd7e12498097/21b4d/sp500-leverage-comparison.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABrElEQVQoz51Tya7bMBDz//9fLy3wgIdsiJPIsvZtyIdR0nQ59NABKGvGMjUi5cUYg8PhiMPxgHVdUUsBRCgyQIIaz5HE6By9s5WMHAPd/Uq7XmCuFz5On6itYUkpIbodrTYIgAFiCFk6UBW1IYeAaDdktyGYG3JwzCkxl8aUC1prjMGj984l50y/PXg9nbiHyhK8AsXeWdzGFj1Liqy1srVOXT90x1+hB4CI0DmHpfc+C2W7opsTRvboOTI5+yJqHPIHwZMF+Ak8U9Bai0Xb1cgpMvmdMRfW1llqY+v93cRvBH9DB44xUGvFMsaYn7RW6UPgf8T0DCJQPxa3W+7GvJ3U90PAVIW5YQpfw84eHcNjZdjMU45SGHZLZwy8tYypoJSCJXiP849vWE9nhEq41BFjgbMe0Xk0t6F6i7DdYdczLh/fEaxRR2Efd1w/P2DvN6zHA5VrkZfg5nKcu0u0lOTY93XOR1ctBwX/PnX2lpuaEmOcFzL5HWG7QfOYEkJMCCkh5Ty1UcFzzvNYb7zyZ73Oq6OmYEIEQ/QnkQm8ngole697QevPubxz7fULDe9aH02OMpAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader.com leveraged stock ETFs",
            "title": "TuringTrader.com leveraged stock ETFs",
            "src": "/static/d251b5b10bddf4f96d81dd7e12498097/c1b63/sp500-leverage-comparison.png",
            "srcSet": ["/static/d251b5b10bddf4f96d81dd7e12498097/5a46d/sp500-leverage-comparison.png 300w", "/static/d251b5b10bddf4f96d81dd7e12498097/0a47e/sp500-leverage-comparison.png 600w", "/static/d251b5b10bddf4f96d81dd7e12498097/c1b63/sp500-leverage-comparison.png 1200w", "/static/d251b5b10bddf4f96d81dd7e12498097/21b4d/sp500-leverage-comparison.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`When increasing leverage, we hope to see returns and risk scale up proportionally. The chart above compares S&P 500 index ETFs with 1x, 2x, and 3x leverage. `}</p>
    <p>{`As we can see, the reality is, unfortunately, more complicated than that. The risks scale up faster than the leverage, with the 3x leveraged ETF showing more than four times the standard deviation of returns. Simultaneously, the returns scale up slower than the leverage, with the 2x ETF only outperforming by 26%, and the 3x ETF even lagging the unleveraged index.`}</p>
    <small>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Metric`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`S&P 500`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`2x`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`3x`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Start (01/03/2007)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`$1,000`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`$1,000`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`$1,000`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`End (08/14/2020)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`$3,137.53`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`$4,170.97`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`$2,660.10`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`CAGR`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`8.76% (1.00x)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`11.06% (1.26%)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`7.45% (0.85x)`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`STDEV`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`20.51% (1.00x)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`47.79% (2.33x)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`86.35% (4.21x)`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`MDD`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`55.19% (1.00x)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`84.34% (1.53x)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`96.05% (1.74x)`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Ulcer Index`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`14.63% (1.00x)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`32.97% (2.25x)`}</td>
          </tr>
        </tbody>
      </table>
    </small>
    <p>{`If we only focus on the 10-year bull market after the 2008 recession, the 3x ETF seems to outperform the 2x variant, but likely not by enough to justify its additional risk. The table above summarizes the key metrics.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b49a63c33dd77b164369dfbc0d303105/21b4d/bond-leverage-comparison.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAByElEQVQoz31Ta2/bMAz0//9p+7YBRZFtzbrEj8QPWbIl6303WEm6tgNG4CCKOh5IgqqmccTpfC5omhbaGKSUmFICciaAD0gpM2c8OIUXU+LQnuCsRRVjwMNyzgX8a7sPvANzgt8033P0PHCVAjHGXTCWaL5XsOfcE+nUSDtfb+/OUNRHqvYX1dhz6V65Xk+0osM8Ccw6UmuNKoRQVKK3cNbcFAmKrubv7wf2bUPVvlJ0Dbur4CQN1y1QLpZqtZyEgvV7Z6BS6lZh8I6XtuPl95GmP7M/PnNoahqXGRI5TYLWxU+T+Gg5J4QQWCkxcR57eh8ohwv75sRFKVrn+H97NIOHOrz3qKQQlOPAXTjhRkskvdGcu5pa2xLzPtLojdvmqJUs3Ji5n1ik5DxOe4Wo5DRiaBvUxx/o2ivUskGOE+rDE45fv+B8eIIcRoj2XPzu5zMup1fMXQPZd9iWBfXLAcenbzR6RZVyfmtibV/opoZ2aripqVSWc+I6tNxXJbitzDunSKMEt2WmEf1bvpQSlTGmLKRzHlItmOcZcllR4s6Vuaxmg/Me3oeCEg8RPgTY+91ae2t53/T3eCz3jsc9BI/PvH9w/xB/AMnmVB9ngXe/AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader.com leveraged treasury ETFs",
            "title": "TuringTrader.com leveraged treasury ETFs",
            "src": "/static/b49a63c33dd77b164369dfbc0d303105/c1b63/bond-leverage-comparison.png",
            "srcSet": ["/static/b49a63c33dd77b164369dfbc0d303105/5a46d/bond-leverage-comparison.png 300w", "/static/b49a63c33dd77b164369dfbc0d303105/0a47e/bond-leverage-comparison.png 600w", "/static/b49a63c33dd77b164369dfbc0d303105/c1b63/bond-leverage-comparison.png 1200w", "/static/b49a63c33dd77b164369dfbc0d303105/21b4d/bond-leverage-comparison.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The chart above makes the same comparison for U.S. Treasury bonds with 20+ Years to maturity. Compared to the S&P 500 ETFs, this chart looks much better. The 2x leveraged ETF outperforms the unleveraged variant by 55% and without overly inflating its risk:`}</p>
    <small>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Metric`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`20+ Yr Bonds`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`2x`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`3x`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Start (01/03/2007)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`$1,000`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`$1,000`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`$1,000`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`End (08/14/2020)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`$2,808.76`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`$4,815.31`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`$4,982.89`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`CAGR`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`7.88% (1.00x)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`12.24% (1.55x)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`12.52% (1.59x)`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`STDEV`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`15.09% (1.00x)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`31.94% (2.12x)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`51.59% (3.42x)`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`MDD`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`26.59% (1.00x)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`47.57% (1.79x)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`67.38% (2.53x)`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Ulcer Index`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`11.17% (1.00x)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`22.85% (2.05x)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`35.30% (3.16x)`}</td>
          </tr>
        </tbody>
      </table>
    </small>
    <p>{`Like before, the 3x ETF does not deliver on its promise: Its performance is almost identical to the 2x ETF, while its risk increased substantially.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a7c3f5c4851079f174e114611b3a112a/21b4d/60-40-leverage-comparison.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABe0lEQVQoz5VT2Y7bMAz0//9k06Jtks1h6ySpY6aQctQL7ENLYECRskjOSF6u1wsOh284n8+otbK1NoDhAbzxsg6wdcx9VaWZQaKjv52hqlhqLRjWe3+d2vu/AFA1T5QcWXJEiRstbLCcKCmi1DoKVu4Mn4LeWFSYvWMKgfGJHBPVKrX0AZRGWAVDCFhEZIyMHNNMVjNqFsb1xuDc6EzJmaWBg0PftXzK8WQBeu+xqAhNhevxJ+PlyBQ8JQYW06nXfvi9pjvMj4buU8PWOh+Jyug3dnT+p701nwWD9/S3K83KpGOVNBGqvz/opsToPQeT6B3DemeOYcZTW7dhSBLc9iiYYsR6PuL34TtWl6F+Q16v2C4nqGSoCKL3GK8hOof7xwk5BhQz+PWO9eOEFAJup1/MOQ/Kbc6r7kq5/GCRyPL55v/Jem9c1w1LSgmmiuGdcxA1jMdqppPCQE7psRZ5576Kx3DL869AH+h9oj3XLz8atPaI2xf7Lz8m/QO6CVzxPCGI/AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader.com leveraged 60/40 portfolios",
            "title": "TuringTrader.com leveraged 60/40 portfolios",
            "src": "/static/a7c3f5c4851079f174e114611b3a112a/c1b63/60-40-leverage-comparison.png",
            "srcSet": ["/static/a7c3f5c4851079f174e114611b3a112a/5a46d/60-40-leverage-comparison.png 300w", "/static/a7c3f5c4851079f174e114611b3a112a/0a47e/60-40-leverage-comparison.png 600w", "/static/a7c3f5c4851079f174e114611b3a112a/c1b63/60-40-leverage-comparison.png 1200w", "/static/a7c3f5c4851079f174e114611b3a112a/21b4d/60-40-leverage-comparison.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`The Magic of Diversification`}</h2>
    <p>{`The performance comparison of leveraged assets showed quite sobering results. But what happens if we combine these assets into a portfolio? `}</p>
    <p>{`The results demonstrate the magic of diversification. Combining the assets into a simple 60/40 portfolio turned into a big win. The portfolio shows significantly improved characteristics with returns and risks scaling up much closer to the expected levels.`}</p>
    <small>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Metric`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`60/40 Portfolio`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`2x`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`3x`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Start (01/02/2007)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`$1,000`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`$1,000`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`$1,000`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`End (08/14/2020)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`3,331.62`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`7,025.27`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`9,948.07`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`CAGR`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`9.24% (1.00x)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`15.39% (1.67x)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`18.38% (1.99x)`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`STDEV`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`11.07% (1.00x)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`23.70% (2.14x)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`38.12% (3.44x)`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`MDD`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`31.14% (1.00x)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`57.52% (1.84x)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`76.83% (2.47x)`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Ulcer Index`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`6.35% (1.00x)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`14.66% (2.31x)`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`24.97% (3.93x)`}</td>
          </tr>
        </tbody>
      </table>
    </small>
    <p>{`Further, we see that the sweet spot for leverage is around 2x. At 3x leverage, the returns don't improve enough to compensate for the additional risk. While the 3x variant shows potential in the bull market after 2008, reaping these returns would require the successful management of drawdowns first.`}</p>
    <h2>{`Adding Portfolio Management`}</h2>
    <p>{`While the results of the simple leveraged 60/40 are quite encouraging, a maximum drawdown of 58% during the financial crisis is only acceptable to the bravest investors among us. To reign this in, we need portfolio management. There are many management styles to choose from. For this article, we decided upon the following main characteristics:`}</p>
    <ul>
      <li parentName="ul">{`use no more than 2x leverage`}</li>
      <li parentName="ul">{`choose a balanced baseline asset allocation for inherent stability`}</li>
      <li parentName="ul">{`carefully tilt this asset allocation to improve the risk profile`}</li>
      <li parentName="ul">{`aim for low-frequency rebalancing`}</li>
    </ul>
    <p>{`All these rules are interrelated and depend on each other: Starting with a well-behaved baseline asset allocation enables us to keep the leverage up and to get away with infrequent and relatively subtle adjustments to the portfolio. Further, limiting ourselves to 2x leverage allows us to keep things simple, while more leverage would require more sophisticated management. The 2x leverage is what inspired the portfolio's name: Say hello to `}<em parentName="p">{`Dos Equis`}</em>{`.`}</p>
    <p><em parentName="p">{`Dos Equis`}</em>{` starts with the following baseline, all assets using 2x leverage:`}</p>
    <ul>
      <li parentName="ul">{`54% U.S. Stock Market`}</li>
      <li parentName="ul">{`38% 20+ Year U.S. Treasuries`}</li>
      <li parentName="ul">{`8% Gold`}</li>
    </ul>
    <p>{`To manage risk, `}<em parentName="p">{`Dos Equis`}</em>{` adjusts these allocations based on historical volatility over the past month. This approach reflects the ideas explored in our article about `}<a parentName="p" {...{
        "href": "/articles/volatility-targeting/"
      }}>{`volatility targeting`}</a>{`. In that article, we found that volatility is not only a measure of risk but is often also related to future returns.`}</p>
    <p><em parentName="p">{`Dos Equis`}</em>{`' mechanism of adjusting position sizes is quite simple. We start by setting a volatility target shared by the main assets. `}<em parentName="p">{`Dos Equis`}</em>{` then scales back position sizes based on the individual asset's historical volatility over the past month in proportion to that target. `}</p>
    <p>{`Due to position sizes scaling back with volatility, times of elevated volatility will leave some unallocated capital. `}<em parentName="p">{`Dos Equis`}</em>{` uses this capital in two ways:`}</p>
    <ul>
      <li parentName="ul">{`allocate up to 15% toward a volatility-linked ETP, based on a simple VIX forecasting model.`}</li>
      <li parentName="ul">{`allocate any remaining capital to 7-10 Year U.S. Treasuries`}</li>
    </ul>
    <p>{`Volatility, as expressed by the VIX, is typically negatively correlated to U.S. stocks. Therefore, we can partially hedge stocks against sudden downturns by adding long exposure to the VIX.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/255771be5a4575d8abe99bc51079a20a/21b4d/dos-equis-exposure.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACtElEQVQoz42M3S9bARjGz9+yGxd2s+yGbsFGOyJZkFh2NTfDguCcU0p7jiPzubK2TNUoMY1WO/qBTqmhPjZmFxOkTMSYi2VZNh9VLNv7LJoRd9vFL/k9z/PmZdw+PxV3L5I0sILWfj91OsfQ7pxAa/8EvC47+fp7MO62w+N2weLyUpfTC5/LDq/LAY/njH6yD7hg9r6j/VCYGI/TSjm6QWLbxuHQq2mkoQA2rYCuxmrakWTYEmW0I8TQRmUiBbQPMKQrxI4Yi83KBCzVpmOpPpPePs7A885e2j86JmbBZYbYbIOupYc+alPxuS4JI/WFGG0swi/hCkJiNA7FaIQ1UdiuTcSn2mQcC1E4FK/iu3Qde9I12pViMditp1D4iJhdq4a0eiNZDXVYr5LTspgMW70GrhoO6+XxmFFnYlUjx7JagQXpHraEOMyr07CiUWBDSMC2cJM+qBUYMuvo5OiAmJ2uUtibimBvUKFPXQxR2YxHNW5IUhvSikeQwk0ijfMhkx+GqsQEk0qDW+ybSK4rfQJdaTVl8X30oqUd4a/bxIx3tmLKkAZTTRXU/DNkcS/BVlhQWtYBWfF7JHEzSGRncYNdRK6yFzlKK2TsIu5yYygvMUJdYqQU1o9OgwmnP74QU9O+AIeYDW15AzK4V1CwAZSp2pCttCGOnYecm45wm51DOudDMjcZ8TtcIPI0g/eRgn2NBr2FQgchYko61qmZK6NClYUS+AWS83N0XzlMqfwUJfFzpOBnLzjb5Jf8bE/k5ymV80NoHKRvBz+JURqXkJptRXzBBGLypiHLC0CWNxUhNi8QQZYfuNT/7c5z/jRic/14WDFKe4cnxDgnt6iud42aHGtksAfJYF8jg2ON9BEPksERpKe21Yt80Z+7I0iNtiCZhzfp+OSUGIAIwD/4/R83EfAH4A1wbs0l7b4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader.com: Dos Equis is low-maintenance and tax efficient",
            "title": "TuringTrader.com: Dos Equis is low-maintenance and tax efficient",
            "src": "/static/255771be5a4575d8abe99bc51079a20a/c1b63/dos-equis-exposure.png",
            "srcSet": ["/static/255771be5a4575d8abe99bc51079a20a/5a46d/dos-equis-exposure.png 300w", "/static/255771be5a4575d8abe99bc51079a20a/0a47e/dos-equis-exposure.png 600w", "/static/255771be5a4575d8abe99bc51079a20a/c1b63/dos-equis-exposure.png 1200w", "/static/255771be5a4575d8abe99bc51079a20a/21b4d/dos-equis-exposure.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The chart above shows the resulting exposure over time. The asset allocation is mostly static, with larger shifts occurring as a reaction to increased volatility. Because these adjustments are neither fast-moving nor overly severe, we only need to rebalance once per week without incurring undue timing risk.`}</p>
    <p>{`Even better, the mostly static allocation leads to a good chance of receiving long-term treatment of capital gains.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a7f8744ef0e6b662a69864ebf39bd925/21b4d/dos-equis-equity.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACI0lEQVQoz51TTU8UQRCdP4WJcDGoB28ePejFIJIsES964WqUk4hsvHEhURMFThATwiqiAQ2BAyy47gffC7s7Mz2zOz3fPe+ZWT7VG5W89Ouq7qpKpZ5mCgu6rsOVLpRSVErhBIziGHGsqBKQBImUpPzcAGCtbHFofA3CltCUiv+KJwCTdoKzn+1sfpTA8RT36h4W8ybfTBb5NLvCJ6MruDUwy7uDc5BeSC2KIpwU4r/V9+oeV4sWx6Yr7Bta4sPn33lncJ7XM7O81p9jd/8cbzzKofvxN9x7tkxDNKHF8XmHkSIXNwSHP5TY8+Inbw7k2Nkzw477n9jxYI5XenO82veZXZmv7MossDOzwK7+BXT0fsHtwSXqpg3tsNrgesnk9pHk/PIBh9+t8+X4Kkfe5zk6UWR2qszsVIWjUxW+niwfY+L4HJkopRyvPpY4NrMFPwipIVGkbJCxTSLkJexs1r7vQ9verXMpr/NHXmdVxDTMFkWtxpYpaAub1f06ZbPJlhDcKFS5t6+zWjXoOg7XC0f8VW7AsWzahkAYhtB2Stt4O72J8dkD6Ic1OIYOaZnwnRY8KWHUdES+h9CVKBZ2cHRQgzAsxIGPwuYWfhd2Ecom3Po+HUdCazcbGmRokrHLS1sSUVg2NOl6cC0DrmggiBSCIPgPnuchCHz4ftDGqT+d2endDwKkK5guNhSQaqCtkCRJTnDO09mcxi6+aSvqgi9t9A8bYiDOLJTa2gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader.com: Dos Equis delivers high returns",
            "title": "TuringTrader.com: Dos Equis delivers high returns",
            "src": "/static/a7f8744ef0e6b662a69864ebf39bd925/c1b63/dos-equis-equity.png",
            "srcSet": ["/static/a7f8744ef0e6b662a69864ebf39bd925/5a46d/dos-equis-equity.png 300w", "/static/a7f8744ef0e6b662a69864ebf39bd925/0a47e/dos-equis-equity.png 600w", "/static/a7f8744ef0e6b662a69864ebf39bd925/c1b63/dos-equis-equity.png 1200w", "/static/a7f8744ef0e6b662a69864ebf39bd925/21b4d/dos-equis-equity.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The portfolio's resulting equity curve offers no surprises. It closely tracks that of the 2x leveraged 60/40 we started with. However, in times of high volatility, especially in 2008 and 2020, `}<em parentName="p">{`Dos Equis`}</em>{` fares better through managing risk.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/95431df4f89eac25a8b843cea9cb609b/21b4d/dos-equis-tracking.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABIklEQVQoz5VSi27DMAjM///mpKnT2q5p/AADx4TTtG4zVRrSxQTwiTNMOWeknJBSAhFBVV1VcYMD8LA4h9zmu5mCm/i8EIgqJhHxF8OLD6ysGHJPNSzmn8cCqgVTa+1+6Wb+H8RVM/jHVygtjw4fBXt/jO0J126JBVTrM+F43vUM5H9ZSJpT64Sl5JAsuw5Gss24mVdWP83sl6X5JTX/WTqQawzHUMcOfbh8OFU/nKsvRTpyFb9m6URBTM083k0NLvFxfxA6FJ/Hiu8LgZvhfGVk0vtkqcWa2DjlHUJYEMYKdslzEk9VPJP6m+d6a2bmvcPj+Yp5KZDGYCYwM4i4L/mGUsrq8/q/1tAOEZ9i801XWd0369DbGYjCLTfW2EssOv0FSSRjhK0OZjQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader.com: Dos Equis beats the market",
            "title": "TuringTrader.com: Dos Equis beats the market",
            "src": "/static/95431df4f89eac25a8b843cea9cb609b/c1b63/dos-equis-tracking.png",
            "srcSet": ["/static/95431df4f89eac25a8b843cea9cb609b/5a46d/dos-equis-tracking.png 300w", "/static/95431df4f89eac25a8b843cea9cb609b/0a47e/dos-equis-tracking.png 600w", "/static/95431df4f89eac25a8b843cea9cb609b/c1b63/dos-equis-tracking.png 1200w", "/static/95431df4f89eac25a8b843cea9cb609b/21b4d/dos-equis-tracking.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Many investors have the goal of `}<em parentName="p">{`beating the market`}</em>{`. Compared to the S&P 500 benchmark, `}<em parentName="p">{`Dos Equis`}</em>{` can handily deliver on this goal. The graph above shows how `}<em parentName="p">{`Dos Equis`}</em>{` continuously increases its lead over the S&P 500.`}</p>
    <h2>{`Applications`}</h2>
    <p>{`We see `}<em parentName="p">{`Dos Equis`}</em>{` as a well-behaved yet straightforward solution for a wide range of investment objectives. Because the portfolio invests in no more than 5 ETFs, requirements for initial capital are low. Thanks to the weekly rebalancing schedule, `}<em parentName="p">{`Dos Equis`}</em>{` works well for DIY investors with a busy life. The portfolio's high returns, combined with its tax-efficiency, make it a suitable choice for taxable accounts.`}</p>
    <p>{`However, the portfolio requires that investors feel comfortable with using leverage. Many brokerages consider leveraged ETFs high-risk assets and need their clients to sign additional disclosures before allowing them to use these products.`}</p>
    <p>{`To learn more about `}<em parentName="p">{`Dos Equis`}</em>{`, see the dedicated portfolio page with daily updated charts and metrics.`}</p>
    <ButtonPrimary text="Check Out Dos Equis" to="/portfolios/tt-dos-equis/" mdxType="ButtonPrimary" />
    <br /><br /><br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      